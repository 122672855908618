import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BoardBreadcrumb from "../components/BoardBreadcrumb";
import BoardHeader from "../components/BoardHeader";
import BoardArticleCard from "../components/BoardArticleCard";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import FourOhFour from "../pages/404";

const useStyles = makeStyles((theme) => ({
  pagination: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
  },
}));

function SingleResourceType({ data, pageContext }) {
  const all_articles = data.articles?.edges ? data.articles.edges : [];
  const limit = 2;
  const [currentPage, setCurrentPage] = useState(1);
  const [pagedArticles, setPagedArticles] = useState(getPagedArticles());
  const classes = useStyles();
  const numPages = Math.ceil(all_articles.length / limit);

  function getPagedArticles() {
    return [...all_articles].splice(limit * (currentPage - 1), limit);
  }

  useEffect(() => {
    setPagedArticles(getPagedArticles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <>
      <BoardHeader />
      <div id="board-articles">
        <Container>
          <BoardBreadcrumb current={data.category.edges[0].node.name} />

          <Grid container spacing={6}>
            {pagedArticles.map(({ node }) => (
              <Grid key={node.drupal_id} item xs={12} sm={6}>
                <BoardArticleCard {...node} />
              </Grid>
            ))}
          </Grid>

          {numPages > 1 && (
            <Pagination
              className={classes.pagination}
              page={pageContext.currentPage}
              count={numPages}
              size="large"
              onChange={(e) => {
                setCurrentPage(e.target.getAttribute("pagenum"));
              }}
              renderItem={(item) => <PaginationItem pagenum={item.page} {...item} />}
            />
          )}

          {!numPages && <FourOhFour archive />}
        </Container>
      </div>
    </>
  );
}

export default SingleResourceType;

export const query = graphql`
  query($slug: String!) {
    category: allTaxonomyTermResourceType(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          drupal_id
          name
          fields {
            slug
          }
        }
      }
    }
    articles: allNodeKnowledgeBaseArticles(
      filter: {
        relationships: {
          field_category: { elemMatch: { fields: { slug: { eq: $slug } } } }
        }
      }
      sort: { fields: field_date, order: DESC }
    ) {
      edges {
        node {
          drupal_id
          field_date(formatString: "MMMM Do, YYYY")
          title
          fields {
            slug
          }
          body {
            processed
            summary
          }
          relationships {
            field_category {
              drupal_id
              name
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        drupalUrl
      }
    }
  }
`;
