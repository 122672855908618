import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  fourohfourNumber: {
    fontFamily: theme.typography.headings.fontFamily,
    fontSize: 200,
    fontWeight: 700,
    letterSpacing: 6,
    color: grey["300"],
    lineHeight: 1,
  },
  fourohfourContainer: {
    textAlign: "center",
    paddingTop: 40,
    paddingBottom: 40,
  },
}));

function FourOhFour({ archive }) {
  const classes = useStyles();
  return (
    <Container maxWidth="sm">
      <Box my={4} className={classes.fourohfourContainer}>
        {!archive && <Typography className={classes.fourohfourNumber}>404</Typography>}
        <Typography variant="h4" gutterBottom>
          {archive ? <>No Results</> : <>Nothing Found</>}
        </Typography>
        {archive ? (
          <Typography>No entries found on this page</Typography>
        ) : (
          <Typography>Sorry this page doesn't exist</Typography>
        )}
      </Box>
    </Container>
  );
}

export default FourOhFour;
