import { navigate } from "gatsby";
import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    textDecoration: "none",
    height: "100%",
    padding: "0",
    color: "inherit",
    position: "relative",
    paddingBottom: "3rem",
    "&:hover, &:active, &:focus": {
      "& h2": {
        color: theme.palette.secondary.main,
      },
    },
    "&::after": {
      content: "' '",
      backgroundColor: theme.palette.secondary.light,
      display: "block",
      height: 1,
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
    },
    "& h2": {
      marginBottom: "0.5em",
    },
  },
}));

function BoardCategoryCard({ body, drupal_id, title, fields, relationships }) {
  const classes = useStyles();
  return (
    <a
      href={fields.slug}
      className={classes.root}
      onClick={(e) => {
        e.preventDefault();
        navigate(fields.slug);
      }}
    >
      <Typography variant="h3" component="h2">
        {title}
      </Typography>
      {body?.summary && <Typography>{body.summary}</Typography>}
    </a>
  );
}

export default BoardCategoryCard;
