import React from "react";
import CallToAction from "./CallToAction";

function BoardHeader({ heading_type = "h2", searchTerm, setSearchTerm }) {
  return (
    <CallToAction
      id="board-cta"
      title="Resources for the Board"
      heading_type={heading_type}
      is_board={true}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
    />
  );
}

export default BoardHeader;
